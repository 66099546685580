<template>
  <div
    class="secureBox"
    v-resize-ob="windowResize"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#FFFFFF"
  >
    <div class="title" :style="titleStyle">
      <span>
        {{ titleText }}
      </span>
    </div>
    <div class="paddingBox" :style="paddingBoxStyle">
      <div class="secureArea" :style="secureAreaStyle">
        <template v-if="emailRegisterCurrent == 'phone'">
          <VerifyPhone
            @selectThisAreaCode="selectThisAreaCode"
            @getInputText="getInputText"
            @getVerificationText="getVerificationText"
            :colorStyle="colorStyle"
          ></VerifyPhone>
        </template>
        <template v-if="emailRegisterCurrent == 'email'">
          <VerifyEmail
            @getInputText="getInputText"
            @getVerificationText="getVerificationText"
            :colorStyle="colorStyle"
          ></VerifyEmail>
        </template>
        <template v-if="emailRegisterCurrent == 'QA'">
          <VerifyQA
            :colorStyle="colorStyle"
            :type="'emailQA'"
            @getNewAnswer="getNewAnswer"
          ></VerifyQA>
        </template>
      </div>
      <div class="secureFooter" :style="secureFooterStyle">
        <div
          class="confirm"
          v-waves
          :class="disable ? 'disable' : ''"
          @click.stop="confirm"
        >
          <span class="iconfont_Me icon-check iconStyle"></span>
        </div>
        <div class="back" @click.stop="goBack">
          <span
            class="iconfont_Me icon-return iconStyle"
            :style="secureFooterIconStyle"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import basicMixin from "../loginDialog/basic";
import VerifyPhone from "@/newVersion/components/verifyPhone/index.vue";
import VerifyEmail from "@/newVersion/components/verifyEmail/index.vue";
import VerifyQA from "@/newVersion/components/verifyQA/index.vue";
import isEmail from "validator/lib/isEmail";
import { registEmail } from "@/api/newVersion/melinked_email";
export default {
  mixins: [basicMixin],
  components: {
    VerifyPhone,
    VerifyEmail,
    VerifyQA,
  },
  data() {
    return {
      colorStyle: "#F39945",
      secureInfo: {
        countryCode: "",
        phone: "",
        phoneCode: "",
        email: "",
        emailCode: "",
        answers: null,
      },
      loading: false,
      timer: null,
    };
  },
  computed: {
    emailRegisterCurrent() {
      return this.$store.state.melinked_email.emailRegisterCurrent;
    },
    registerFnencryptInfo() {
      return this.$store.state.melinked_email.registerFnencryptInfo;
    },
    titleText() {
      let text = "";
      switch (this.emailRegisterCurrent) {
        case "phone":
          text = `${this.$t("bind")}${this.$t("phone")}`;
          break;
        case "email":
          text = `${this.$t("bind")}${this.$t("email")}`;
          break;
        case "QA":
          text = `${this.$t("bind")}${this.$t("secureQuestion")}`;
          break;
        default:
          break;
      }
      return text;
    },
    disable() {
      let disable = true;
      let inputValues = [];
      switch (this.emailRegisterCurrent) {
        case "phone":
          inputValues = [
            this.secureInfo.phone,
            this.secureInfo.phoneCode,
            this.secureInfo.countryCode,
          ];
          break;
        case "email":
          inputValues = [this.secureInfo.email, this.secureInfo.emailCode];
          break;
      }
      if (this.emailRegisterCurrent == "QA") {
        const firstVerify =
          this.secureInfo.answers && this.secureInfo.answers.length != 0;
        if (firstVerify) {
          disable = this.secureInfo.answers.some(
            (item) => !item.answer || item.answer == ""
          );
        }
      } else {
        disable = inputValues.some((item) => item == "");
      }
      return disable;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    async confirm() {
      switch (this.emailRegisterCurrent) {
        case "email":
          if (!isEmail(this.secureInfo.email)) {
            this.$message({
              type: "error",
              message: this.$t("inputEmailTips"),
            });
            return;
          }
          break;
      }
      const setLoginInfo = _.every(this.registerFnencryptInfo, (item) => {
        return item != "";
      });
      if (setLoginInfo) {
        let params = { ...this.secureInfo, ...this.registerFnencryptInfo };
        this.loading = true;
        const result = await registEmail(params);
        if (result.code == 200 && result.data.data) {
          // 如果是已登录状态则展示提示窗
          if (this.userInfo.id) {
            this.loading = false;
            this.$store.commit("setEmail_loginAndRegisterDialog", false);
            this.$store.commit("setEmail_Login_RegisterTips", {
              from: "register",
            });
          } else {
            this.$store.commit("setResultDialog", {
              type: "success",
              text: this.$t("successfulRegistrationPrompt"),
            });
            if (this.timer) {
              clearTimeout(this.timer);
            }
            const loginParams = {
              userName: this.registerFnencryptInfo.userName,
              userPassword: this.registerFnencryptInfo.password,
              remember: true,
              userNameType: 2,
              successFn: () => {
                this.loading = false;
              },
            };
            this.$store.commit("setEmailRegisterFnencryptInfo", null);
            const loginKey = this.$Base64.encode(JSON.stringify(loginParams));
            localStorage.setItem("emailReloadKey", loginKey);
            await this.global_login(loginParams);
          }
        } else {
          this.loading = false;
          let message = result.message;
          if (result.message == "success") {
            message = this.$t("registrationFailurePrompt");
          }
          this.$message({
            type: "error",
            message: message,
          });
        }
      }
    },
    selectThisAreaCode(areaCode) {
      this.secureInfo.countryCode = areaCode;
    },
    getInputText(text) {
      switch (this.emailRegisterCurrent) {
        case "phone":
          this.secureInfo.phone = text;
          break;
        case "email":
          this.secureInfo.email = text;
          break;
      }
    },
    getVerificationText(code) {
      switch (this.emailRegisterCurrent) {
        case "phone":
          this.secureInfo.phoneCode = code;
          break;
        case "email":
          this.secureInfo.emailCode = code;
          break;
      }
    },
    getNewAnswer(QA) {
      this.secureInfo.answers = QA.map((item) => item.secureQA);
    },
    goBack() {
      this.$store.commit("setEmailRegisterHistoryGoBack");
    },
  },
};
</script>
<style lang="stylus" scoped>
.secureBox
  width: 100%;
  height: 100%;
  padding-top: 1px;
  .title
    width: 100%;
    color: #292D32;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: 400;
  .paddingBox
    .secureArea
      width: 100%;
      box-sizing: border-box;
    .secureFooter
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .confirm
        width: 100%;
        flex: 50;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F39945;
        cursor: pointer;
        .iconStyle
          color: #FFFFFF;
          font-size: 16px;
      .disable
        background: #D9D9D9 !important;
        cursor: not-allowed !important;
      .back
        width: 100%;
        flex: 40;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .iconStyle
          color: #B3B3B3;
          cursor: pointer;
</style>
<style lang="stylus">
.secureBox
  .secureArea
    .el-form
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .el-form-item
        height: 100%;
        width: 100%;
        .el-form-item__content
          height: 100%;
          width: 100%;
</style>
