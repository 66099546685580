var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.windowResize,
          expression: "windowResize"
        },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "email_login",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "#FFFFFF"
      }
    },
    [
      _c("div", { staticClass: "title", style: _vm.titleStyle }, [
        _c("span", [
          _vm._v("\n      " + _vm._s(_vm.$t("emailLogin")) + "\n    ")
        ])
      ]),
      _c("div", { staticClass: "paddingBox", style: _vm.paddingBoxStyle }, [
        _c(
          "div",
          { staticClass: "inputArea", style: _vm.inputAreaStyle },
          [
            _c(
              "el-form",
              {
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                    return (() => false).apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "userName" } },
                      [
                        _c(
                          "CommonInput",
                          { on: { inputChange: _vm.getEmailText } },
                          [
                            _c("el-image", {
                              style: _vm.imageIconStyle,
                              attrs: {
                                src: require("../../../../assets/images/email/loginDialog/email_not_selected.png"),
                                fit: "cover"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "emailPassword" } },
                      [
                        _c(
                          "CommonInput",
                          {
                            attrs: {
                              passwordInput: true,
                              needInputshowError: _vm.inputError
                            },
                            on: { inputChange: _vm.getPasswordText }
                          },
                          [
                            _c("span", {
                              staticClass:
                                "iconfont_Me icon-password-fill iconStyle",
                              style: _vm.fontIconStyle
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "forgotPassword",
            style: _vm.forgotPasswordStyle,
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.forget.apply(null, arguments)
              }
            }
          },
          [
            _c("span", [
              _vm._v("\n        " + _vm._s(_vm.$t("forgetPwd")) + "\n      ")
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "buttonBox", style: _vm.loginButtonBoxStyle },
          [
            _c(
              "div",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "button",
                class: _vm.disable ? "disable" : "",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.startLogin.apply(null, arguments)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me icon-check iconStyle",
                  style: _vm.buttonIconStyle
                })
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "noEmailBox",
            style: _vm.noEmailBoxStyle,
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.goRegister.apply(null, arguments)
              }
            }
          },
          [_c("span", [_vm._v(" " + _vm._s(_vm.$t("noEmail")) + " > ")])]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }