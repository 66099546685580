var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.handlerResize,
          expression: "handlerResize"
        }
      ],
      staticClass: "componentBox"
    },
    [
      _c("div", { staticClass: "interactiveBox" }, [
        _c(
          "div",
          {
            staticClass: "questionOuterBox",
            style: _vm.questionOuterBoxStyle,
            attrs: { title: _vm.questionItem.question }
          },
          [
            _c(
              "div",
              {
                staticClass: "questionIconBox",
                style: _vm.questionIconBoxStyle
              },
              [
                _c("transition", { attrs: { name: "transitionStyle" } }, [
                  _vm.inputIsGetFocus
                    ? _c(
                        "div",
                        { staticClass: "questionIcon" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src:
                                _vm.inputType == "emailQA"
                                  ? require("../../../assets/images/email/loginDialog/selector_Q.png")
                                  : require("../../../assets/images/newLogin/questionIconSelectedStyle.png"),
                              fit: "fill"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("transition", { attrs: { name: "transitionStyle" } }, [
                  !_vm.inputIsGetFocus
                    ? _c(
                        "div",
                        { staticClass: "questionIcon" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src:
                                _vm.inputType == "emailQA"
                                  ? require("../../../assets/images/email/loginDialog/QA_not_selected.png")
                                  : require("../../../assets/images/newLogin/questionIcon.png"),
                              fit: "fill"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            ),
            _c(
              "div",
              {
                ref: "questionBox",
                staticClass: "questionBox",
                style: _vm.questionBoxStyle
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.questionItem.question) + "\n      "
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "operatingArea",
                style: {
                  height: `${_vm.realTimeComputing(28)}px`,
                  width: `${_vm.realTimeComputing(28)}px`
                }
              },
              [
                _vm.needCloseQuestion
                  ? _c("span", {
                      staticClass: "iconfont_Me icon-x iconStyle",
                      style: { "font-size": `${_vm.realTimeComputing(12)}px` },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.closeQuestionBox.apply(null, arguments)
                        }
                      }
                    })
                  : _vm._e()
              ]
            )
          ]
        ),
        _c("div", { staticClass: "borderBox", style: _vm.borderBoxStyle }, [
          _c("div", { staticClass: "answerBox", style: _vm.answerBoxStyle }, [
            _c(
              "div",
              {
                staticClass: "answerIcon",
                style: {
                  width: `${_vm.realTimeComputing(20)}px`,
                  height: `${_vm.realTimeComputing(20)}px`
                }
              },
              [
                _c("el-image", {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: {
                    src:
                      _vm.inputType == "emailQA"
                        ? require("../../../assets/images/email/loginDialog/answer_icon.png")
                        : require("../../../assets/images/newLogin/answerIcon.png"),
                    fit: "fill"
                  }
                })
              ],
              1
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputText,
                  expression: "inputText"
                }
              ],
              ref: "answerInput",
              staticClass: "answerInput",
              style: _vm.answerInputStyle,
              attrs: { type: "text" },
              domProps: { value: _vm.inputText },
              on: {
                focus: function($event) {
                  $event.stopPropagation()
                  return _vm.inputGetFocus.apply(null, arguments)
                },
                blur: function($event) {
                  $event.stopPropagation()
                  return _vm.inputLosesFocus.apply(null, arguments)
                },
                input: [
                  function($event) {
                    if ($event.target.composing) return
                    _vm.inputText = $event.target.value
                  },
                  function($event) {
                    $event.stopPropagation()
                    return _vm.inputContentChange.apply(null, arguments)
                  }
                ]
              }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }