var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.windowResize,
          expression: "windowResize"
        }
      ],
      staticClass: "securityVerification"
    },
    [
      _c("div", { staticClass: "title", style: _vm.titleStyle }, [
        _c("span", [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("setupSecurityVerification")) + "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "paddingBox", style: _vm.paddingBoxStyle }, [
        _c(
          "div",
          {
            staticClass: "setupTips",
            style: _vm.setupTipsStyle,
            attrs: { title: _vm.$t("setupTips") }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("setupTips")) + "\n    ")]
        ),
        _c(
          "div",
          { staticClass: "setupButtonBox", style: _vm.setupButtonBoxStyle },
          _vm._l(_vm.buttons, function(i, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "setupButton",
                style: _vm.setupButtonStyle,
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.selectThis(i.type)
                  }
                }
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "iconStyle defaultIcon",
                      style: _vm.setupButtonIcon
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: require(`../../../../assets/images/email/loginDialog/${i.type}_not_selected.png`),
                          fit: "cover"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "iconStyle hoverIcon",
                      style: _vm.setupButtonIcon
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: require(`../../../../assets/images/email/loginDialog/${i.type}_selected.png`),
                          fit: "cover"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "nextIcon defaultIcon",
                      style: _vm.setupButtonNextIcon
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          src: require("../../../../assets/images/email/loginDialog/setup_not_selected.png"),
                          fit: "cover"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "nextIcon hoverIcon",
                      style: _vm.setupButtonNextIcon
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          src: require("../../../../assets/images/email/loginDialog/setup_selected.png"),
                          fit: "cover"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "backBox", style: _vm.backBoxStyle }, [
          _c("span", {
            staticClass: "iconfont_Me icon-return iconStyle",
            style: { "font-size": `${_vm.realTimeComputing(28)}px` },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.goBack.apply(null, arguments)
              }
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }