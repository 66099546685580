var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.handlerResize,
          expression: "handlerResize"
        }
      ],
      staticClass: "format"
    },
    [
      _c(
        "div",
        { staticClass: "questions" },
        _vm._l(_vm.setUpSecurityQuestions, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "inputOuterStyle",
              style: {
                "margin-top": `${
                  index == 0
                    ? _vm.realTimeComputing(52)
                    : _vm.realTimeComputing(38)
                }px`
              }
            },
            [
              item.showSelecter
                ? _c(
                    "QuestionListPopover",
                    {
                      attrs: {
                        handlerQuestionsList: _vm.listOfQuestions,
                        selecterIndex: index,
                        colorStyle: _vm.colorStyle
                      },
                      on: { selectQuestion: _vm.selectQuestion }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "questionSelecter",
                          style: _vm.questionSelecterStyle
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "selecterLeft",
                              style: {
                                height: `${_vm.realTimeComputing(50)}px`
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "questionIcon",
                                  style: {
                                    height: `${_vm.realTimeComputing(20)}px`,
                                    width: `${_vm.realTimeComputing(20)}px`
                                  }
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%"
                                    },
                                    attrs: {
                                      src:
                                        _vm.type == "emailQA"
                                          ? require("../../../assets/images/email/loginDialog/QA_not_selected.png")
                                          : require("../../../assets/images/newLogin/questionIconLarge.png"),
                                      fit: "fill"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "questionText",
                                  style: _vm.questionTextStyle
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("setAnswer")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "selecterRight",
                              style: { width: `${_vm.realTimeComputing(16)}px` }
                            },
                            [
                              _c("span", {
                                staticClass: "iconfont_Me icon-down iconStyle",
                                style: {
                                  "font-size": `${_vm.realTimeComputing(14)}px`
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              !item.showSelecter
                ? _c("QAInputEmail", {
                    attrs: {
                      needCloseQuestion: true,
                      questionItem: item.secureQA,
                      autoFocus: !item.showSelecter,
                      indexInParent: index,
                      colorStyle: _vm.colorStyle,
                      inputType: _vm.type
                    },
                    on: {
                      getAnswer: _vm.getNewAnswer,
                      closeQuestionBox: _vm.closeQuestionBox
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }