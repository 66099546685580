var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.handlerResize,
          expression: "handlerResize"
        }
      ],
      staticClass: "verifyEmail"
    },
    [
      _c("div", { staticClass: "inputAreaBox" }, [
        _c(
          "div",
          { staticClass: "inputOuter", style: _vm.inputOuterStyle },
          [
            _c(
              "CommonInput",
              {
                attrs: { colorStyle: _vm.colorStyle },
                on: { inputChange: _vm.inputEmail }
              },
              [
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    src: require("../../../assets/images/email/loginDialog/email_not_selected.png")
                  }
                })
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showVerificationCode,
                expression: "showVerificationCode"
              }
            ],
            staticClass: "inputOuter verificationCodeBox",
            style: _vm.inputOuterStyle
          },
          [
            _c(
              "div",
              {
                staticClass: "leftInputBox",
                style: _vm.verificationInputStyle
              },
              [
                _c(
                  "CommonInput",
                  {
                    attrs: {
                      colorStyle: _vm.colorStyle,
                      onlyType: "number",
                      needInputshowError: _vm.showError
                    },
                    on: { inputChange: _vm.getVerificationCode }
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        "max-width": "100%",
                        "max-height": "100%"
                      },
                      attrs: {
                        src: require("@/assets/images/newLogin/securityIcon.png")
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "rightButtonStyle",
                class: _vm.sendCodeDisable
                  ? "rightSendButton_disabled"
                  : "rightSendButton",
                style: {
                  border: `1px solid ${
                    _vm.sendCodeDisable ? "rgba(0,0,0,0.1)" : _vm.colorStyle
                  }`
                },
                attrs: { title: _vm.$t("verificationCode") },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.sendVerificationCodeForEmail.apply(
                      null,
                      arguments
                    )
                  }
                }
              },
              [
                !_vm.hasSendVerificationCode
                  ? _c(
                      "span",
                      {
                        staticClass: "codeBlock",
                        style: {
                          color: `${
                            _vm.sendCodeDisable
                              ? "rgba(0,0,0,0.1)"
                              : _vm.colorStyle
                          }`,
                          "font-size": `${
                            _vm.realTimeComputing(14) < 12
                              ? 12
                              : _vm.realTimeComputing(14)
                          }px`
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("verificationCode")) +
                            "\n        "
                        )
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "countdownStyle",
                        style: { color: _vm.colorStyle }
                      },
                      [_vm._v(_vm._s(_vm.countdownText) + "S")]
                    )
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }