var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.windowResize,
          expression: "windowResize"
        }
      ],
      staticClass: "email_register"
    },
    [
      _c("div", { staticClass: "title", style: _vm.titleStyle }, [
        _c("div", { staticClass: "personalRegistration" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("emailRegister")) + "\n    ")
        ]),
        _c("div", { staticClass: "lineStyle" }),
        _c(
          "div",
          {
            staticClass: "businessRegistration",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startBusinessRegistration.apply(null, arguments)
              }
            }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("businessRegistration")) + "\n    "
            )
          ]
        )
      ]),
      _c("div", { staticClass: "paddingBox", style: _vm.paddingBoxStyle }, [
        _c(
          "div",
          { staticClass: "inputArea", style: _vm.inputAreaStyle },
          [
            _c(
              "el-form",
              {
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                    return (() => false).apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "userName" } },
                      [
                        _c(
                          "CommonInput",
                          {
                            attrs: {
                              mailbox: _vm.env("EMAIL_DOMAIN"),
                              needInputshowError: _vm.needInputshowError,
                              checkAccountSuccess: _vm.checkAccountSuccess,
                              maxlength: 100,
                              noChinese: true
                            },
                            on: { inputChange: _vm.getEmailText }
                          },
                          [
                            _c("el-image", {
                              style: _vm.imageIconStyle,
                              attrs: {
                                src: require("../../../../../assets/images/email/loginDialog/email_not_selected.png"),
                                fit: "cover"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.showErrorTips
                  ? _c(
                      "div",
                      { staticClass: "errorTips", style: _vm.errorTipsStyle },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("emailInputTips")) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "emailPassword" } },
                      [
                        _c(
                          "CommonInput",
                          {
                            attrs: { passwordInput: true },
                            on: { inputChange: _vm.getPasswordText }
                          },
                          [
                            _c("span", {
                              staticClass:
                                "iconfont_Me icon-password-fill iconStyle",
                              style: _vm.fontIconStyle
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "userAgreement", style: _vm.userAgreementStyle },
          [
            _c(
              "div",
              {
                staticClass: "checkBox",
                style: {
                  background: _vm.agreeTheAgreement ? "#F39945" : "#FFFFFF",
                  border: _vm.agreeTheAgreement
                    ? "1px solid #F39945"
                    : "1px solid #DCDFE6",
                  width: `${_vm.realTimeComputing(16)}px`,
                  height: `${_vm.realTimeComputing(16)}px`
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.changeAgreement.apply(null, arguments)
                  }
                }
              },
              [
                _vm.agreeTheAgreement
                  ? _c("span", {
                      staticClass: "iconfont_Me icon-check iconStyle",
                      style: { "font-size": `${_vm.realTimeComputing(14)}px` }
                    })
                  : _vm._e()
              ]
            ),
            _c(
              "span",
              {
                style: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.changeAgreement.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("haveReadAndAgree")) + "\n      "
                )
              ]
            ),
            _c(
              "span",
              {
                staticClass: "specialStyle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    if ($event.target !== $event.currentTarget) return null
                    return _vm.readAgreement.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("userAgreement")) + "\n      ")]
            )
          ]
        ),
        _c("div", { staticClass: "buttonBox" }, [
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "button",
              class: _vm.disable ? "disable" : "",
              style: _vm.registerbuttonStyle,
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.startRegister.apply(null, arguments)
                }
              }
            },
            [
              _c("span", {
                staticClass: "iconfont_Me icon-next iconStyle",
                style: _vm.buttonIconStyle
              })
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }