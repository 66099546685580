<template>
  <div class="format" v-resize-ob="handlerResize">
    <div class="questions">
      <div
        class="inputOuterStyle"
        v-for="(item, index) in setUpSecurityQuestions"
        :key="index"
        :style="{
          'margin-top': `${
            index == 0 ? realTimeComputing(52) : realTimeComputing(38)
          }px`,
        }"
      >
        <QuestionListPopover
          :handlerQuestionsList="listOfQuestions"
          v-if="item.showSelecter"
          :selecterIndex="index"
          :colorStyle="colorStyle"
          @selectQuestion="selectQuestion"
        >
          <div class="questionSelecter" :style="questionSelecterStyle">
            <div
              class="selecterLeft"
              :style="{ height: `${realTimeComputing(50)}px` }"
            >
              <div
                class="questionIcon"
                :style="{
                  height: `${realTimeComputing(20)}px`,
                  width: `${realTimeComputing(20)}px`,
                }"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    type == 'emailQA'
                      ? require('../../../assets/images/email/loginDialog/QA_not_selected.png')
                      : require('../../../assets/images/newLogin/questionIconLarge.png')
                  "
                  fit="fill"
                ></el-image>
              </div>
              <div class="questionText" :style="questionTextStyle">
                {{ $t("setAnswer") }}
              </div>
            </div>
            <div
              class="selecterRight"
              :style="{ width: `${realTimeComputing(16)}px` }"
            >
              <span
                class="iconfont_Me icon-down iconStyle"
                :style="{ 'font-size': `${realTimeComputing(14)}px` }"
              ></span>
            </div>
          </div>
        </QuestionListPopover>
        <QAInputEmail
          v-if="!item.showSelecter"
          :needCloseQuestion="true"
          :questionItem="item.secureQA"
          :autoFocus="!item.showSelecter"
          :indexInParent="index"
          @getAnswer="getNewAnswer"
          @closeQuestionBox="closeQuestionBox"
          :colorStyle="colorStyle"
          :inputType="type"
        ></QAInputEmail>
      </div>
    </div>
  </div>
</template>
<script>
import { getSecuresQuestions } from "@/newVersion/components/accountSecurityDialog/api";
import QAInputEmail from "@/newVersion/components/inputComponent/QAInput_email";
import QuestionListPopover from "@/newVersion/components/questionListPopover";
export default {
  props: {
    colorStyle: {
      type: String,
      default: "#33CC66",
    },
    type: {
      type: String,
      default: "normal",
    },
  },
  components: {
    QAInputEmail,
    QuestionListPopover,
  },
  data() {
    return {
      // 全部问题列表
      listOfQuestions: [],
      // 设置安全问答的列表
      setUpSecurityQuestions: [
        {
          secureQA: {},
          showSelecter: true,
        },
        {
          secureQA: {},
          showSelecter: true,
        },
        {
          secureQA: {},
          showSelecter: true,
        },
      ],
      // 宽度监听
      widthListener: 0,
      // 基准宽度
      originalWidth: 360,
    };
  },
  async mounted() {
    if (this.questionAnswers.length === 0) {
      await this.handlerGetQuestiongList();
    }
    this.listOfQuestions = this.questionAnswers;
  },
  computed: {
    questionSelecterStyle() {
      return {
        height: `${this.realTimeComputing(50)}px`,
        "border-radius": `${this.realTimeComputing(4)}px`,
        padding: `0 ${this.realTimeComputing(18)}px`,
      };
    },
    questionTextStyle() {
      return {
        height: `${this.realTimeComputing(20)}px`,
        "padding-left": `${this.realTimeComputing(10)}px`,
        "font-size": `${this.realTimeComputing(14)}px`,
      };
    },
    // 获取安全问题列表
    questionAnswers() {
      return this.$store.state.accountStore.secureInfo.allSecureQuestionAnswers
        ? _.cloneDeep(
            this.$store.state.accountStore.secureInfo.allSecureQuestionAnswers
          )
        : [];
    },
  },
  methods: {
    realTimeComputing(actualValue) {
      const countWidth = Math.ceil(
        (actualValue / this.originalWidth) * this.widthListener
      );
      return countWidth > actualValue ? actualValue : countWidth;
    },
    // 监听大小改变动态改变样式
    handlerResize(resize) {
      this.widthListener = resize.width;
    },
    // 获取系统安全问题集合
    async handlerGetQuestiongList() {
      const result = await getSecuresQuestions();
      if (result && result.code === 200) {
        let secureInfo = _.cloneDeep(this.$store.state.accountStore.secureInfo);
        secureInfo.allSecureQuestionAnswers =
          result.data.data.filter((item) => !item.deleted) || [];
        this.$store.commit("setSecureInfo", secureInfo);
      }
    },
    // 选择当前问题
    selectQuestion(questionItem, index) {
      this.setUpSecurityQuestions[index].secureQA = {
        answer: "",
        providerId: "0",
        question: questionItem.question,
        questionId: questionItem.id,
      };
      this.setUpSecurityQuestions[index].showSelecter = false;
      this.$forceUpdate();
      this.handlerQuestionsList();
    },
    // 获取新设置的答案
    getNewAnswer(answerItem, index) {
      this.setUpSecurityQuestions[index].secureQA = answerItem;
      this.$emit("getNewAnswer", this.setUpSecurityQuestions);
      this.$forceUpdate();
    },
    // 关闭问题，重新选择问题
    closeQuestionBox(index) {
      this.setUpSecurityQuestions[index].secureQA = {};
      this.setUpSecurityQuestions[index].showSelecter = true;
      this.$forceUpdate();
      this.handlerQuestionsList();
    },
    // 每次操作问题后，处理问题列表的数据结构
    handlerQuestionsList() {
      // 同步刷新问题列表中被禁用的选项
      this.$nextTick(() => {
        let handlerQuestionsList = _.cloneDeep(this.listOfQuestions);
        // 先全部重置为未选中
        handlerQuestionsList = handlerQuestionsList.map((element) => {
          element.hasSelected = false;
          return element;
        });
        handlerQuestionsList = handlerQuestionsList.map((element) => {
          this.setUpSecurityQuestions.forEach((item) => {
            if (
              item.secureQA.questionId &&
              element.id === item.secureQA.questionId
            ) {
              element.hasSelected = true;
            }
          });
          return element;
        });
        this.listOfQuestions = handlerQuestionsList;
        this.$forceUpdate();
      });
    },
    save() {
      const QA = this.setUpSecurityQuestions.map((item) => item.secureQA);
    },
  },
};
</script>
<style lang="stylus" scoped>
.format
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  .questions
    width 100%;
    overflow hidden;
    .inputOuterStyle
      .questionSelecter
        width 100%;
        border 1px solid #8F8F8F;
        cursor pointer;
        user-select none;
        box-sizing border-box;
        display flex;
        align-items center;
        justify-content flex-start;
        .selecterLeft
          flex 1;
          min-width 0;
          display flex;
          align-items center;
          justify-content flex-start;
          .questionIcon
            flex-shrink 0;
          .questionText
            flex 1;
            box-sizing border-box;
            overflow hidden;
            text-overflow ellipsis;
            white-space: nowrap;
            color #CCCCCC;
            user-select none;
        .selecterRight
          height 100%;
          flex-shrink 0;
          display flex;
          align-items center;
          justify-content center;
          .iconStyle
            color #CCCCCC;
</style>
