<template>
  <div class="securityVerification" v-resize-ob="windowResize">
    <div class="title" :style="titleStyle">
      <span>
        {{ $t("setupSecurityVerification") }}
      </span>
    </div>
    <div class="paddingBox" :style="paddingBoxStyle">
      <div class="setupTips" :title="$t('setupTips')" :style="setupTipsStyle">
        {{ $t("setupTips") }}
      </div>
      <div class="setupButtonBox" :style="setupButtonBoxStyle">
        <div
          class="setupButton"
          :style="setupButtonStyle"
          v-for="(i, index) in buttons"
          :key="index"
          @click.stop="selectThis(i.type)"
        >
          <div class="content">
            <div class="iconStyle defaultIcon" :style="setupButtonIcon">
              <el-image
                style="width: 100%; height: 100%"
                :src="
                  require(`../../../../assets/images/email/loginDialog/${i.type}_not_selected.png`)
                "
                :fit="'cover'"
              ></el-image>
            </div>
            <div class="iconStyle hoverIcon" :style="setupButtonIcon">
              <el-image
                style="width: 100%; height: 100%"
                :src="
                  require(`../../../../assets/images/email/loginDialog/${i.type}_selected.png`)
                "
                :fit="'cover'"
              ></el-image>
            </div>
            <div class="nextIcon defaultIcon" :style="setupButtonNextIcon">
              <el-image
                style="width: 100%;"
                :src="
                  require('../../../../assets/images/email/loginDialog/setup_not_selected.png')
                "
                :fit="'cover'"
              ></el-image>
            </div>
            <div class="nextIcon hoverIcon" :style="setupButtonNextIcon">
              <el-image
                style="width: 100%;"
                :src="
                  require('../../../../assets/images/email/loginDialog/setup_selected.png')
                "
                :fit="'cover'"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="backBox" :style="backBoxStyle">
        <span
          class="iconfont_Me icon-return iconStyle"
          :style="{ 'font-size': `${realTimeComputing(28)}px` }"
          @click.stop="goBack"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import basicMixin from "../loginDialog/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      buttons: [
        {
          type: "phone",
        },
        {
          type: "email",
        },
        {
          type: "QA",
        },
      ],
    };
  },
  computed: {},
  methods: {
    selectThis(type) {
      this.$store.commit("setEmailRegisterCurrentHistory", type);
    },
    goBack() {
      this.$store.commit("setEmailRegisterHistoryGoBack");
    },
  },
};
</script>
<style lang="stylus" scoped>
.securityVerification
  width: 100%;
  height: 100%;
  .title
    width: 100%;
    color: #292D32;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: 400;
  .paddingBox
    .setupTips
      width: 100%;
      background: #F9F9F9;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:2;
      color: #858585;
      box-sizing: border-box;
      padding: 0 10px;
      word-break: break-word;
    .setupButtonBox
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .setupButton
        box-sizing: border-box;
        border: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        &:hover
          border: 1px solid #F39945;
          .content
            .defaultIcon
              display: none;
            .hoverIcon
              display: block;
        .content
          height: 100%;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .defaultIcon
            display: block;
          .hoverIcon
            display: none;
          .nextIcon
            position: absolute;
            z-index: 1;
            left: 50%;
            transform: translate3d(-50%,0,0);
    .backBox
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .iconStyle
        color: #B3B3B3;
        cursor: pointer;
</style>
