export default {
  data() {
    return {
      widthListener: 0,
      originalWidth: 520,
    }
  },
  computed: {
    // 标题样式
    titleStyle() {
      return {
        "font-size": `${this.realTimeComputing(24)}px`,
        "margin-top": `${this.realTimeComputing(45)}px`,
      };
    },
    // 统一内边距
    paddingBoxStyle() {
      return {
        padding: `0px ${this.realTimeComputing(60)}px`
      }
    },
    // 输入区域样式
    inputAreaStyle() {
      return {
        "margin-top": `${this.realTimeComputing(70)}px`,
      };
    },
    // 绑定安全信息区域样式
    secureAreaStyle() {
      return {
        "margin-top": `${this.emailRegisterCurrent != 'QA'?this.realTimeComputing(50):0}px`,
        height: `${this.emailRegisterCurrent != 'QA'?this.realTimeComputing(318):this.realTimeComputing(370)}px`,
      };
    },
    // 输入框图片图标样式
    imageIconStyle() {
      return {
        width: `${this.realTimeComputing(25)}px`,
        height: `${this.realTimeComputing(25)}px`
      }
    },
    // 输入框字体图标样式
    fontIconStyle() {
      return {
        'font-size':`${this.realTimeComputing(28)}px`,
      }
    },
    // 忘记密码区域样式
    forgotPasswordStyle() {
      return {
        "margin-top": `${this.realTimeComputing(8)}px`,
        'font-size':`${this.realTimeComputing(16)}px`,
      };
    },
    // 用户协议区域样式
    userAgreementStyle() {
      return {
        "margin-top": `${this.realTimeComputing(8)}px`,
        'font-size':`${this.realTimeComputing(16)}px`,
      }
    },
    // 登录按钮区域样式
    loginButtonBoxStyle() {
      return {
        "margin-top": `${this.realTimeComputing(100)}px`,
      };
    },
    // 注册按钮图标样式
    registerbuttonStyle() {
      return {
        'margin-bottom':`${this.realTimeComputing(50)}px`,
      }
    },
    errorTipsStyle() {
      return {
        'font-size':`${this.realTimeComputing(14)}px`,
        'line-height':`${this.realTimeComputing(20)}px`,
      }
    },
    // 按钮图标样式
    buttonIconStyle() {
      return {
        'font-size':`${this.realTimeComputing(22)}px`,
      }
    },
    // 提示语样式
    noEmailBoxStyle() {
      return {
        "margin-top": `${this.realTimeComputing(12)}px`,
        'font-size':`${this.realTimeComputing(18)}px`,
      };
    },
    // 设置安全信息提示标语样式
    setupTipsStyle() {
      return {
        "margin-top": `${this.realTimeComputing(50)}px`,
        'font-size':`${this.realTimeComputing(16)}px`,
        height: `${this.realTimeComputing(50)}px`,
      };
    },
    // 选择设置安全信息按钮区域样式
    setupButtonBoxStyle() {
      return {
        "margin-top": `${this.realTimeComputing(66)}px`,
        "margin-bottom": `${this.realTimeComputing(146)}px`,
      };
    },
    // 设置安全信息选择按钮样式
    setupButtonStyle() {
      return {
        height: `${this.realTimeComputing(108)}px`,
        width: `${this.realTimeComputing(100)}px`,
        'border-radius': `${this.realTimeComputing(4)}px`,
      };
    },
    // 设置安全信息选择按钮图标样式
    setupButtonIcon() {
      return {
        height: `${this.realTimeComputing(28)}px`,
        width: `${this.realTimeComputing(28)}px`,
      }
    },
    // 设置安全信息选择按钮下一步图标样式
    setupButtonNextIcon() {
      return {
        width: `${this.realTimeComputing(25)}px`,
        height: `${this.realTimeComputing(4)}px`,
        bottom: `${this.realTimeComputing(20)}px`,
      }
    },
    // 返回按钮区域样式
    backBoxStyle() {
      return {
        height: `${this.realTimeComputing(28)}px`,
      }
    },
    // 安全验证底部区域
    secureFooterStyle() {
      return {
        height: `${this.realTimeComputing(90)}px`,
      }
    },
    // 安全验证底部图标
    secureFooterIconStyle() {
      return {
        'font-size':`${this.realTimeComputing(28)}px`,
      }
    }
  },
  methods: {
    realTimeComputing(actualValue) {
      const countWidth = Math.ceil((actualValue / this.originalWidth) * this.widthListener)
      return countWidth > actualValue ? actualValue : countWidth;
    },
    windowResize(ob) {
      this.widthListener = ob.width;
    },
  }
}