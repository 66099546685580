<template>
  <div class="verifyEmail" v-resize-ob="handlerResize">
    <div class="inputAreaBox">
      <div class="inputOuter" :style="inputOuterStyle">
        <CommonInput @inputChange="inputEmail" :colorStyle="colorStyle">
          <img
            style="width: 100%"
            :src="
              require('../../../assets/images/email/loginDialog/email_not_selected.png')
            "
          />
        </CommonInput>
      </div>
      <div
        class="inputOuter verificationCodeBox"
        v-show="showVerificationCode"
        :style="inputOuterStyle"
      >
        <div class="leftInputBox" :style="verificationInputStyle">
          <CommonInput
            @inputChange="getVerificationCode"
            :colorStyle="colorStyle"
            :onlyType="'number'"
            :needInputshowError="showError"
          >
            <img
              style="max-width: 100%;max-height:100%"
              :src="require('@/assets/images/newLogin/securityIcon.png')"
            />
          </CommonInput>
        </div>
        <div
          class="rightButtonStyle"
          :class="
            sendCodeDisable ? 'rightSendButton_disabled' : 'rightSendButton'
          "
          :style="{
            border: `1px solid ${
              sendCodeDisable ? 'rgba(0,0,0,0.1)' : colorStyle
            }`,
          }"
          @click.stop="sendVerificationCodeForEmail"
          :title="$t('verificationCode')"
        >
          <span
            v-if="!hasSendVerificationCode"
            class="codeBlock"
            :style="{
              color: `${sendCodeDisable ? 'rgba(0,0,0,0.1)' : colorStyle}`,
              'font-size': `${
                realTimeComputing(14) < 12 ? 12 : realTimeComputing(14)
              }px`,
            }"
          >
            {{ $t("verificationCode") }}
          </span>
          <span v-else class="countdownStyle" :style="{ color: colorStyle }"
            >{{ countdownText }}S</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonInput from "@/newVersion/components/inputComponent/commonInput";
import { sendValidateCode } from "@/newVersion/components/accountSecurityDialog/api";
import isEmail from "validator/lib/isEmail";
export default {
  name: "VerifyEmail",
  components: {
    CommonInput,
  },
  props: {
    colorStyle: {
      type: String,
      default: "#33CC66",
    },
  },
  data() {
    return {
      // 宽度监听
      widthListener: 0,
      // 基准宽度
      originalWidth: 360,
      // 验证码输入错误
      showError: false,
      // 邮件地址内容
      emailText: "",
      // 邮件验证码
      verificationTextForEmail: "",
      // 已发送验证码
      hasSendVerificationCode: false,
      // 发送倒计时
      countdownText: 0,
      // 倒计时定时器对象
      countdownTimer: null,
    };
  },
  watch: {
    // 监听已发送邮箱的验证码,开始倒计时
    hasSendVerificationCode: {
      handler(val, old) {
        if (val && val !== old) {
          clearTimeout(this.countdownTimer);
          this.countdownText = 60;
          this.handleCountdown();
        }
      },
      deep: true,
    },
  },
  computed: {
    showVerificationCode() {
      return this.emailText !== "";
    },
    sendCodeDisable() {
      return this.emailText == "" || this.hasSendVerificationCode;
    },
    inputOuterStyle() {
      return {
        height: `${this.realTimeComputing(46)}px`,
        "margin-bottom": `${this.realTimeComputing(18)}px`,
      };
    },
    verificationInputStyle() {
      return {
        width: `${this.realTimeComputing(196)}px`,
      };
    },
  },
  methods: {
    realTimeComputing(actualValue) {
      const countWidth = Math.ceil(
        (actualValue / this.originalWidth) * this.widthListener
      );
      return countWidth > actualValue ? actualValue : countWidth;
    },
    // 监听大小改变动态改变样式
    handlerResize(resize) {
      this.widthListener = resize.width;
    },
    inputEmail(text) {
      this.emailText = text;
      this.$emit("getInputText", text);
    },
    getVerificationCode(code) {
      this.verificationTextForEmail = code;
      this.$emit("getVerificationText", code);
    },
    async sendVerificationCodeForEmail() {
      if (this.emailText === "") {
        this.$message({
          type: "error",
          message: "The Email cannot be empty",
        });
        return;
      }
      // 如果还在倒计时中，就不能再发送
      if (this.hasSendVerificationCode) {
        return;
      }
      if (!isEmail(this.emailText)) {
        this.$message({
          type: "error",
          message: this.$t("inputEmailTips"),
        });
        return;
      }
      try {
        let params = {
          email: this.emailText,
        };
        let result = await sendValidateCode(params);
        if (result.code === 200) {
          this.hasSendVerificationCode = true;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } catch (e) {}
    },
    // 倒计时计算操作
    handleCountdown() {
      this.countdownTimer = setTimeout(() => {
        if (this.countdownText > 1) {
          this.countdownText--;
          this.handleCountdown();
        } else {
          clearTimeout(this.countdownTimer);
          this.hasSendVerificationCode = false;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="stylus" scoped>
.verifyEmail
  width 100%;
  overflow hidden;
  .inputAreaBox
    width 100%;
    overflow hidden;
    margin 20px 0 50px 0;
    min-height 110px;
    .inputOuter
      width 100%;
      overflow hidden;
      &:last-child
        margin-bottom 0;
      .iconStyle
        color #666666;
        font-size 20px;
    .verificationCodeBox
      display flex;
      align-items center;
      justify-content flex-start;
      .leftInputBox
        width 275px;
        height 100%;
        flex-shrink 0;
      .rightButtonStyle
        flex 1;
        height 100%;
        margin-left 5px;
        box-sizing border-box;
        border-radius 4px;
        user-select none;
        cursor pointer;
        display flex;
        align-items center;
        justify-content center;
        transition background .3s;
        padding: 0 10px;
        .codeBlock
          display: -webkit-box;
          -webkit-line-clamp: 2; // 设置两行文字溢出
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
      .rightSendButton
        &:hover
          background #F2FCF8;
      .rightSendButton_disabled
        cursor not-allowed;
        &:hover
          background transparent !important;
        .countdownStyle
          color #33CC66;
          font-size 14px;
</style>
<style lang="stylus">
.verifyEmail
  .el-input-group__append
    background #3c6 !important;
    color: #ffffff !important;
    padding 0 !important;
    span
      display: flex;
      width: 111px;
      height: 38px;
      cursor pointer;
      align-items center;
      justify-content center;
</style>
