var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.windowResize,
          expression: "windowResize"
        },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "secureBox",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "#FFFFFF"
      }
    },
    [
      _c("div", { staticClass: "title", style: _vm.titleStyle }, [
        _c("span", [_vm._v("\n      " + _vm._s(_vm.titleText) + "\n    ")])
      ]),
      _c("div", { staticClass: "paddingBox", style: _vm.paddingBoxStyle }, [
        _c(
          "div",
          { staticClass: "secureArea", style: _vm.secureAreaStyle },
          [
            _vm.emailRegisterCurrent == "phone"
              ? [
                  _c("VerifyPhone", {
                    attrs: { colorStyle: _vm.colorStyle },
                    on: {
                      selectThisAreaCode: _vm.selectThisAreaCode,
                      getInputText: _vm.getInputText,
                      getVerificationText: _vm.getVerificationText
                    }
                  })
                ]
              : _vm._e(),
            _vm.emailRegisterCurrent == "email"
              ? [
                  _c("VerifyEmail", {
                    attrs: { colorStyle: _vm.colorStyle },
                    on: {
                      getInputText: _vm.getInputText,
                      getVerificationText: _vm.getVerificationText
                    }
                  })
                ]
              : _vm._e(),
            _vm.emailRegisterCurrent == "QA"
              ? [
                  _c("VerifyQA", {
                    attrs: { colorStyle: _vm.colorStyle, type: "emailQA" },
                    on: { getNewAnswer: _vm.getNewAnswer }
                  })
                ]
              : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "secureFooter", style: _vm.secureFooterStyle },
          [
            _c(
              "div",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "confirm",
                class: _vm.disable ? "disable" : "",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.confirm.apply(null, arguments)
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
            ),
            _c(
              "div",
              {
                staticClass: "back",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.goBack.apply(null, arguments)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me icon-return iconStyle",
                  style: _vm.secureFooterIconStyle
                })
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }