<template>
  <div class="email_register" v-resize-ob="windowResize">
    <div class="title" :style="titleStyle">
      <div class="personalRegistration">
        {{ $t("emailRegister") }}
      </div>
      <div class="lineStyle"></div>
      <div class="businessRegistration" @click.stop="startBusinessRegistration">
        {{ $t("businessRegistration") }}
      </div>
    </div>
    <div class="paddingBox" :style="paddingBoxStyle">
      <div class="inputArea" :style="inputAreaStyle">
        <el-form @submit.stop="() => false">
          <div class="input">
            <el-form-item prop="userName">
              <CommonInput
                @inputChange="getEmailText"
                :mailbox="env('EMAIL_DOMAIN')"
                :needInputshowError="needInputshowError"
                :checkAccountSuccess="checkAccountSuccess"
                :maxlength="100"
                :noChinese="true"
              >
                <el-image
                  :style="imageIconStyle"
                  :src="
                    require('../../../../../assets/images/email/loginDialog/email_not_selected.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </CommonInput>
            </el-form-item>
          </div>
          <div class="errorTips" :style="errorTipsStyle" v-if="showErrorTips">
            {{ $t("emailInputTips") }}
          </div>
          <div class="input">
            <el-form-item prop="emailPassword">
              <CommonInput @inputChange="getPasswordText" :passwordInput="true">
                <span
                  class="iconfont_Me icon-password-fill iconStyle"
                  :style="fontIconStyle"
                ></span>
              </CommonInput>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="userAgreement" :style="userAgreementStyle">
        <div
          class="checkBox"
          @click.stop="changeAgreement"
          :style="{
            background: agreeTheAgreement ? '#F39945' : '#FFFFFF',
            border: agreeTheAgreement
              ? '1px solid #F39945'
              : '1px solid #DCDFE6',
            width: `${realTimeComputing(16)}px`,
            height: `${realTimeComputing(16)}px`,
          }"
        >
          <span
            class="iconfont_Me icon-check iconStyle"
            :style="{ 'font-size': `${realTimeComputing(14)}px` }"
            v-if="agreeTheAgreement"
          ></span>
        </div>
        <span :style="{ cursor: 'pointer' }" @click.stop="changeAgreement">
          {{ $t("haveReadAndAgree") }}
        </span>
        <span class="specialStyle" @click.stop.self="readAgreement"
          >{{ $t("userAgreement") }}
        </span>
      </div>
      <div class="buttonBox">
        <div
          class="button"
          :class="disable ? 'disable' : ''"
          v-waves
          @click.stop="startRegister"
          :style="registerbuttonStyle"
        >
          <span
            class="iconfont_Me icon-next iconStyle"
            :style="buttonIconStyle"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import basicMixin from "../basic";
import CommonInput from "../../../inputComponent/commonInput.vue";
import { checkEmailAvailable } from "@/api/newVersion/melinked_email";
import { env } from "@/utils";
export default {
  mixins: [basicMixin],
  data() {
    return {
      agreeTheAgreement: false,
      userName: "",
      password: "",
      // 账号是否重复验证状态
      checkAccountSuccess: false,
      // 显示错误
      needInputshowError: false,
      // 展示错误提示文字
      showErrorTips: false,
    };
  },
  components: {
    CommonInput,
  },
  computed: {
    disable() {
      let disable = true;
      let inputValues = [this.userName, this.password];
      disable = _.every(inputValues, (value) => {
        return value != "";
      });
      return (
        !disable ||
        !this.agreeTheAgreement ||
        !this.checkAccountSuccess ||
        this.needInputshowError
      );
    },
  },
  methods: {
    changeAgreement() {
      this.agreeTheAgreement = !this.agreeTheAgreement;
    },
    readAgreement() {
      this.$store.commit("setLoginAndRegisterDialog", true);
      this.$store.commit("setLoginAndRegisterDialogSourceType", "email");
      this.$store.commit("setDirectly_enter_privacy_agreement_window", true);
    },
    startBusinessRegistration() {
      this.$store.commit("setLoginAndRegisterDialog", true);
      this.$store.commit("setLoginAndRegisterDialogSourceType", "email");
      this.$store.commit("setTeamWindowType", 0);
      this.$store.commit("setDirectly_enter_team_register_window", true);
    },
    getPasswordText(password) {
      this.password = password;
    },
    getEmailText(userName) {
      if (userName !== "") {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(async () => {
          if (userName.length < 3) {
            this.checkAccountSuccess = false;
          } else {
            let result = await checkEmailAvailable(
              `${userName.toLowerCase()}${env("EMAIL_DOMAIN")}`
            );
            if (result.code == 200 && !result.data.data) {
              this.userName = userName;
              this.checkAccountSuccess = true;
              this.$forceUpdate();
            } else if (result.data.data) {
              this.checkAccountSuccess = false;
            }
          }
          this.needInputshowError = !this.checkAccountSuccess;
        }, 500);
      } else {
        this.userName = "";
      }
    },
    startRegister() {
      if (this.disable) {
        return;
      }
      this.showErrorTips = false;
      let regex = /^[A-Za-z0-9_.]+$/g;
      if (
        !regex.test(this.userName.toLowerCase()) ||
        this.userName.length < 3 ||
        this.userName.length > 100
      ) {
        this.showErrorTips = true;
        return;
      }
      const params = {
        userName: `${this.userName.toLowerCase()}${env("EMAIL_DOMAIN")}`,
        encryptedPassword: this.fnencrypt(this.password),
        password: this.password,
        userNameType: 2,
      };
      this.$store.commit("setEmailRegisterFnencryptInfo", params);
      this.$store.commit(
        "setEmailRegisterCurrentHistory",
        "selectSecurityType"
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
.email_register
  width: 100%;
  height: 100%;
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .title
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: 400;
    flex-shrink: 0;
    .personalRegistration
      color: #292D32;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    .lineStyle
      width: 2px;
      height: 100%;
      background: #D9D9D9;
      margin: 0 10px;
    .businessRegistration
      color: #B3B3B3;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
 .paddingBox
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  .paddingBox
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    width: 100%;
    .inputArea
      width: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
      min-height: 6.78vw;
      max-height: 150px;
      .input
        width: 100%;
        height: 2.6vw;
        max-height: 50px;
        min-height: 35px;
        .iconStyle
          color #666666;
          font-size 20px;
      .errorTips
        width: 100%;
        color: #FF0000;
        word-break: break-word;
    .userAgreement
      width: 100%;
      user-select none;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      .checkBox
        cursor: pointer;
        margin-right 3px;
        border-radius: 3px;
        border: 1px solid #DCDFE6;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
        .iconStyle
          color: #FFFFFF;
      .specialStyle
        color: #F39945;
        cursor: pointer;
    .buttonBox
      width: 100%;
      box-sizing: border-box;
      flex: 1;
      min-height: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column-reverse;
      .button
        height: 2.6vw;
        width: 100%;
        background: #F39945;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle
          color: #FFFFFF;
      .disable
        background: #D9D9D9 !important;
        cursor: not-allowed !important;
</style>
<style lang="stylus">
.email_register
  .inputArea
    .el-form
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .el-form-item
        height: 100%;
        width: 100%;
        .el-form-item__content
          height: 100%;
          width: 100%;
</style>
