<template>
  <div class="componentBox" v-resize-ob="handlerResize">
    <div class="interactiveBox">
      <div
        class="questionOuterBox"
        :title="questionItem.question"
        :style="questionOuterBoxStyle"
      >
        <div class="questionIconBox" :style="questionIconBoxStyle">
          <transition name="transitionStyle">
            <div class="questionIcon" v-if="inputIsGetFocus">
              <el-image
                style="width: 100%;height:100%"
                :src="
                  inputType == 'emailQA'
                    ? require('../../../assets/images/email/loginDialog/selector_Q.png')
                    : require('../../../assets/images/newLogin/questionIconSelectedStyle.png')
                "
                fit="fill"
              ></el-image>
            </div>
          </transition>
          <transition name="transitionStyle">
            <div class="questionIcon" v-if="!inputIsGetFocus">
              <el-image
                style="width: 100%;height:100%"
                :src="
                  inputType == 'emailQA'
                    ? require('../../../assets/images/email/loginDialog/QA_not_selected.png')
                    : require('../../../assets/images/newLogin/questionIcon.png')
                "
                fit="fill"
              ></el-image>
            </div>
          </transition>
        </div>
        <div ref="questionBox" class="questionBox" :style="questionBoxStyle">
          {{ questionItem.question }}
        </div>
        <div
          class="operatingArea"
          :style="{
            height: `${realTimeComputing(28)}px`,
            width: `${realTimeComputing(28)}px`,
          }"
        >
          <span
            v-if="needCloseQuestion"
            class="iconfont_Me icon-x iconStyle"
            @click.stop="closeQuestionBox"
            :style="{ 'font-size': `${realTimeComputing(12)}px` }"
          ></span>
        </div>
      </div>
      <div class="borderBox" :style="borderBoxStyle">
        <div class="answerBox" :style="answerBoxStyle">
          <div
            class="answerIcon"
            :style="{
              width: `${realTimeComputing(20)}px`,
              height: `${realTimeComputing(20)}px`,
            }"
          >
            <el-image
              style="width:100%;height:100%"
              :src="
                inputType == 'emailQA'
                  ? require('../../../assets/images/email/loginDialog/answer_icon.png')
                  : require('../../../assets/images/newLogin/answerIcon.png')
              "
              fit="fill"
            ></el-image>
          </div>
          <input
            type="text"
            :style="answerInputStyle"
            class="answerInput"
            ref="answerInput"
            v-model="inputText"
            @focus.stop="inputGetFocus"
            @blur.stop="inputLosesFocus"
            @input.stop="inputContentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    colorStyle: {
      type: String,
      default: "#33CC66",
    },
    inputType: {
      type: String,
      default: "normal",
    },
    // 问题文本
    questionItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 需要关闭问题的按钮
    needCloseQuestion: {
      type: Boolean,
      default: false,
    },
    // 自动获取焦点
    autoFocus: {
      type: Boolean,
      default: false,
    },
    // 在父组件所属的下标，用于回调方便查找
    indexInParent: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    questionOuterBoxStyle() {
      return {
        margin: `0 ${this.realTimeComputing(18)}px`,
      };
    },
    questionIconBoxStyle() {
      return {
        width: `${this.realTimeComputing(20)}px`,
        height: `${this.realTimeComputing(20)}px`,
        "margin-right": `${this.realTimeComputing(5)}px`,
      };
    },
    questionBoxStyle() {
      return {
        color: this.inputIsGetFocus ? this.colorStyle : "#8F8F8F",
        "line-height": `${this.realTimeComputing(18)}px`,
        "font-size": `${this.realTimeComputing(12)}px`,
      };
    },
    borderBoxStyle() {
      return {
        height: `${this.realTimeComputing(50)}px`,
        "border-radius": `${this.realTimeComputing(4)}px`,
        padding: `0 ${this.realTimeComputing(18)}px`,
      };
    },
    answerBoxStyle() {
      return {
        height: `${this.realTimeComputing(18)}px`,
      };
    },
    answerInputStyle() {
      return {
        "caret-color": this.colorStyle,
        "padding-left": `${this.realTimeComputing(15)}px`,
        "font-size": `${this.realTimeComputing(14)}px`,
      };
    },
  },
  data() {
    return {
      // 输入框获取到焦点
      inputIsGetFocus: false,
      // 输入框文本
      inputText: "",
      // 失去焦点定时器
      timer: null,
      // 宽度监听
      widthListener: 0,
      // 基准宽度
      originalWidth: 360,
    };
  },
  watch: {
    // 监听是否设置了自动获取焦点
    autoFocus: {
      handler(val, old) {
        if (val && val !== old) {
          this.$nextTick(() => {
            this.$refs.answerInput.focus();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    realTimeComputing(actualValue) {
      const countWidth = Math.ceil(
        (actualValue / this.originalWidth) * this.widthListener
      );
      return countWidth > actualValue ? actualValue : countWidth;
    },
    // 监听大小改变动态改变样式
    handlerResize(resize) {
      this.widthListener = resize.width;
    },
    // 点击关闭问题
    closeQuestionBox() {
      console.log("关闭问题");
      this.$emit("closeQuestionBox", this.indexInParent);
    },
    // 输入框获取到焦点
    inputGetFocus() {
      this.inputIsGetFocus = true;
    },
    // 输入框失去焦点
    inputLosesFocus() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.inputIsGetFocus = false;
      }, 200);
    },
    // 输入框内容改变触发
    inputContentChange() {
      let answerItem = {
        answer: this.inputText,
        providerId: this.$store.state.accountStore.secureInfo.providerId,
        question: this.questionItem.question,
        questionId: this.questionItem.questionId,
      };
      this.$emit("getAnswer", answerItem, this.indexInParent);
    },
  },
};
</script>
<style lang="stylus" scoped>
.transitionStyle-enter-active, .transitionStyle-leave-active
  transition: opacity .3s;
.transitionStyle-enter,.transitionStyle-leave-to
  opacity: 0;
.componentBox
  width 100%;
  min-height 0;
  position relative;
  .interactiveBox
    z-index 1;
    width 100%;
    transition opacity .3s;
    position relative;
    .questionOuterBox
      background #FFFFFF;
      box-sizing border-box;
      overflow hidden;
      position relative;
      z-index 2;
      display:inline-flex;
      align-items center;
      justify-content flex-start;
      position: absolute;
      left: 0;
      top:0;
      transform: translate3d(0,-80%,0);
      .questionIconBox
        flex-shrink 0;
        background #FFFFFF;
        position relative;
        .questionIcon
          top 0;
          left 0;
          width 100%;
          height: 100%;
          display flex;
          position absolute;
          align-items center;
          justify-content center;
      .questionBox
        min-width 0;
        background #FFFFFF;
        overflow: hidden;
        cursor pointer;
        transition color .3s;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        user-select none;
      .operatingArea
        flex-shrink 0;
        background #FFFFFF;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          color #CCCCCC;
          cursor pointer;
    .borderBox
      width 100%;
      z-index 1;
      position relative;
      box-sizing border-box;
      border 1px solid #8F8F8F;
      display: flex;
      align-items: center;
      justify-content: center;
      .answerBox
        width 100%;
        display flex;
        align-items center;
        justify-content flex-start;
        .answerInput
          width 100%;
          flex 1;
          color #343434;
          outline none;
          border none;
</style>
