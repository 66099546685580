<template>
  <div
    class="email_login"
    v-resize-ob="windowResize"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#FFFFFF"
  >
    <div class="title" :style="titleStyle">
      <span>
        {{ $t("emailLogin") }}
      </span>
    </div>
    <div class="paddingBox" :style="paddingBoxStyle">
      <div class="inputArea" :style="inputAreaStyle">
        <el-form @submit.stop="() => false">
          <div class="input">
            <el-form-item prop="userName">
              <CommonInput @inputChange="getEmailText">
                <el-image
                  :style="imageIconStyle"
                  :src="
                    require('../../../../assets/images/email/loginDialog/email_not_selected.png')
                  "
                  :fit="'cover'"
                ></el-image>
              </CommonInput>
            </el-form-item>
          </div>
          <div class="input">
            <el-form-item prop="emailPassword">
              <CommonInput
                @inputChange="getPasswordText"
                :passwordInput="true"
                :needInputshowError="inputError"
              >
                <span
                  class="iconfont_Me icon-password-fill iconStyle"
                  :style="fontIconStyle"
                ></span>
              </CommonInput>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div
        class="forgotPassword"
        :style="forgotPasswordStyle"
        @click.stop="forget"
      >
        <span>
          {{ $t("forgetPwd") }}
        </span>
      </div>
      <div class="buttonBox" :style="loginButtonBoxStyle">
        <div
          class="button"
          v-waves
          @click.stop="startLogin"
          :class="disable ? 'disable' : ''"
        >
          <span
            class="iconfont_Me icon-check iconStyle"
            :style="buttonIconStyle"
          ></span>
        </div>
      </div>
      <div class="noEmailBox" :style="noEmailBoxStyle" @click.stop="goRegister">
        <span> {{ $t("noEmail") }} > </span>
      </div>
    </div>
  </div>
</template>
<script>
import basicMixin from "./basic";
import CommonInput from "../../inputComponent/commonInput.vue";
import { loginByUsername } from "@/api/newVersion/loginAndRegister";
export default {
  mixins: [basicMixin],
  data() {
    return {
      password: "",
      email: "",
      inputError: false,
      enterTipsCount: [],
      loading: false,
    };
  },
  components: {
    CommonInput,
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    disable() {
      let disable = true;
      let inputValues = [this.email, this.password];
      disable = _.every(inputValues, (value) => {
        return value != "";
      });
      return !disable;
    },
  },
  beforeDestroy() {
    this.enterTipsCount = [];
  },
  methods: {
    // 点击忘记密码按钮
    forget() {
      this.$confirm(this.$t("resettingPasswordNoEncrypted"), this.$t("tips"), {
        confirmButtonText: " ",
        type: "warning",
        customClass: "melinked-tips",
        showCancelButton: false,
        confirmButtonClass: "el-icon-right my-confirm-btn",
      })
        .then(() => {
          this.$store.commit("setLoginAndRegisterDialog", true);
          this.$store.commit("setLoginAndRegisterDialogSourceType", "email");
          this.$store.commit(
            "setDirectly_enter_password_recovery_window",
            true
          );
        })
        .catch(() => {});
    },
    getPasswordText(password) {
      this.password = password;
    },
    getEmailText(email) {
      this.email = email;
    },
    goRegister() {
      this.$store.commit("setEmailRegisterCurrentHistory", "register");
    },
    async startLogin() {
      if (this.disable) {
        return;
      }
      this.loading = true;
      this.inputError = false;
      // 如果当前有已登录账号，
      if (this.userInfo.id && !this.enterTipsCount.includes(this.email)) {
        const params = {
          userName: this.email,
          encryptedPassword: this.fnencrypt(this.password),
          password: this.password,
          userNameType: 2,
        };
        this.enterTipsCount.push(this.email);
        this.$store.commit("setEmailRegisterFnencryptInfo", params);
        this.$store.commit("setEmail_Login_RegisterTips", {
          from: "login",
        });
        this.loading = false;
      } else {
        let result = await loginByUsername(
          this.email,
          this.password,
          true,
          2
        ).catch((e) => {
          this.$message({
            type: "error",
            message: this.$t("loginerror"),
          });
          return;
        });
        if (result && result.code === 1 && result.data && result.data.length) {
          const loginParams = {
            userName: this.email,
            userPassword: this.password,
            remember: true,
            userNameType: 2,
            successFn: () => {
              this.loading = false;
            },
          };
          const loginKey = this.$Base64.encode(JSON.stringify(loginParams));
          localStorage.setItem("emailReloadKey", loginKey);
          await this.global_login(loginParams, this.teamRegister);
        } else {
          this.loading = false;
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    //团队注册
    teamRegister(type = 0) {
      // 关闭弹窗,情况弹窗相关数据
      try {
        this.$store.commit("setEmailRegisterFnencryptInfo", null);
        this.$store.commit("setEmail_loginAndRegisterDialog", false);
      } catch (error) {}
      // 打开团队成员修改密码弹窗
      this.$store.commit("setLoginAndRegisterDialog", true);
      this.$nextTick(() => {
        this.$store.commit("setTeamWindowType", type);
        this.$store.commit("setTeamWindowFlag", true);
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.email_login
  width: 100%;
  height: 100%;
  padding-top: 1px;
  .title
    width: 100%;
    color: #292D32;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: 400;
  .paddingBox
    .inputArea
      width: 100%;
      height: 6.78vw;
      max-height: 130px;
      min-height: 80px;
      box-sizing: border-box;
      .input
        width: 100%;
        height: 2.6vw;
        max-height: 50px;
        min-height: 35px;
        .iconStyle
          color #666666;
          font-size 20px;
    .forgotPassword
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span
        cursor: pointer;
        color: #666666;
        user-select: none;
    .buttonBox
      width: 100%;
      height: 2.6vw;
      max-height: 50px;
      min-height: 35px;
      box-sizing: border-box;
      .button
        height: 100%;
        width: 100%;
        background: #F39945;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle
          color: #FFFFFF;
      .disable
        background: #D9D9D9 !important;
        cursor: not-allowed !important;
    .noEmailBox
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #F39945;
      span
        cursor: pointer;
        user-select: none;
</style>
<style lang="stylus">
.email_login
  .inputArea
    .el-form
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .el-form-item
        height: 100%;
        width: 100%;
        .el-form-item__content
          height: 100%;
          width: 100%;
</style>
